import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutSeatMapLink.scss';
import { Tooltip } from '@toolkit/ui';

type Props = {
  seatMapSelectorUrl: string;
  showSelectionWarning?: boolean;
};

const CheckoutSeatMapLink: React.FC<Props> = ({
  seatMapSelectorUrl,
  showSelectionWarning = false,
}) => {
  if (!isNil(seatMapSelectorUrl)) {
    return (
      <a className="lp-checkout-button-link" href={seatMapSelectorUrl}>
        {showSelectionWarning && (
          <Tooltip
            icon="icon-warning"
            iconStyle="seat-warning-icon big"
            alignLeft={true}
            bodyTextFirst={t('redeemedSearchNotification.warning.seatReservationWarning')}
          />
        )}
        <span className="button button-light">
          <i className="icon-seating" />
          &nbsp;{t('checkoutPrepareConn.button.selectSeat')}
        </span>
      </a>
    );
  }
  return null;
};

export default CheckoutSeatMapLink;
